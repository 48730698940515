<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Registered Users</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <loading-bar :value="searching"></loading-bar>
          <v-window
            v-model="window"
          >
            <!-- College List -->
            <v-window-item :key="0">
              <v-card-title>
                <v-text-field
                  v-model="term"
                  append-icon="fas fa-search"
                  label="Search"
                  single-line
                  hide-details clearable
                  @input="search"
                  :loading="searching"
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="users"
                item-key="id"
                :loading="searching"
              >
                <template v-slot:[`item.dtCreated`]="{ item }">
                  {{ item.dtCreated | unixToLocalDt }}
                </template>
                <template v-slot:[`item.lastConnected`]="{ item }">
                  {{ item.lastConnected | unixToLocalDt }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    color="color3 color3Text--text"
                    @click.stop="handleClick(item)"
                    text
                  >
                    <v-icon>fas fa-eye</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-window-item>
            <!-- User Details -->
            <v-window-item :key="1">
              <user-details :user="selected"></user-details>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        v-if="id"
        color="color3 color3Text--text"
        fab bottom fixed @click="() => {$router.back()}"
      >
        <v-icon>fas fa-caret-left</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import debounce from 'lodash.debounce'
const UserDetails = () => import('@/Pages/Admin/UserDetails')

export default {
  props: ['id'],
  data () {
    return {
      loading: false,
      searching: false,
      term: null,
      users: [],
      selected: null,
      details: [],
      coaches: [],
      dialog: false,
      window: 0,
      selectedCoach: null,
      headers: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Username',
          value: 'userName'
        },
        {
          text: 'First Name',
          value: 'firstName'
        },
        {
          text: 'Last Name',
          value: 'lastName'
        },
        {
          text: 'Created On',
          value: 'dtCreated'
        },
        {
          text: 'Last Connect',
          value: 'lastConnected'
        },
        {
          text: '',
          value: 'actions',
          align: 'end'
        }
      ],
      dHeaders: [
        {
          text: 'Prop',
          value: 'label'
        },
        {
          text: '',
          value: 'value'
        }
      ],
      qrLoad: false,
      invite: null
    }
  },
  computed: {
  },
  methods: {
    search: debounce(function () {
      this.doSearch()
    }, 333),
    doSearch (id) {
      if (!this.term || typeof this.term !== 'string' || this.term.length < 3) return
      this.searching = true
      this.$VBL.user.search(this.term, id)
        .then(r => {
          this.users = r.data
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.searching = false
        })
    },
    handleClick (user) {
      this.$router.push(`/admin/user/${user.id}`)
    },
    checkSelected () {
      if (this.id) {
        const u = this.users.find(f => f.id === +this.id)
        if (u) {
          this.selectUser(u)
        } else {
          this.searching = true
          this.$VBL.user.search(null, this.id)
            .then(r => {
              this.users = r.data
              this.selectUser(this.users[0])
            })
            .catch(e => console.log(e))
            .finally(() => {
              this.searching = false
            })
        }
      } else {
        this.window = 0
        this.term = null
      }
    },
    updateCollege (college) {
      const i = this.colleges.findIndex(f => f.id === +this.id)
      if (i > -1) {
        this.colleges.splice(i, 1, college)
        this.selectCollege(college)
      }
    },
    updateCoach (coach) {
      const i = this.selected.coaches.findIndex(f => f.id === +this.id)
      if (i > -1) {
        this.selected.coaches.splice(i, 1, coach)
      }
    },
    selectUser (user) {
      this.selected = user
      this.window = 1
    },
    save (row) {
      if (row && this.selected) {
        const dto = { id: this.selected.id }
        dto[row.label] = row.value
        this.patching = true
        this.$VBL.college.details.patch(dto)
          .then(r => {
            this.updateCollege(r.data)
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.patching = false
          })
      }
    }
  },
  mounted () {
    this.checkSelected()
  },
  watch: {
    id: 'checkSelected'
  },
  components: {
    UserDetails
  }
}
</script>
